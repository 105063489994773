<!--
 * @Author: zoujp
 * @Date: 2020-07-22 09:45:46
 * @LastEditTime: 2021-06-22 16:39:10
 * @LastEditors: Please set LastEditors
 * @Description: 业务建模共享弹窗
-->

<template>
  <en-dialog
    :visible="dialogVisible"
    :append-to-body="true"
    title="选择共享人"
    class="bus-btn-share"
    width="1000px"
    :close-on-click-modal="false"
    @close="closeFn"
  >
    <en-transfer-data v-model="selectData" :config="transferSet"></en-transfer-data>
    <div slot="footer">
      <en-button @click="save">
        确定
      </en-button>
    </div>
  </en-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { Message } from "element-ui";
import { request } from "en-js";
import { getFileUrl } from "@/tools/getFileUrl";
import { btnService } from "@/api/businessModel";
import enTransferData from "@/components/en-transfer-data";

// 过滤
export default {
  name: "Share",
  components: { enTransferData },
  props: {
    // 数据id
    id: String,
    dialogVisible: Boolean
  },
  data() {
    return {
      selectData: [],
      transferSet: {
        hideRight: false,
        exclude: false,
        showTitle: false,
        imgPath: "userLogo",
        dependData: {
          areaObjType: "002"
        },
        filterObj: {
          type: "002"
        }
      }
    };
  },
  watch: {},
  mounted() {
  },
  computed: {
    ...mapGetters(["userInfo", "urlParam"])
  },
  methods: {
    /**
     * @description: 获取人员头像地址
     * @url : 头像地址
     * @return:
     */
    getFileUrl(url) {
      return getFileUrl(url, "000", "");
    },
    // 提交后台
    @request()
    async commit() {
      const userIds = this.selectData.map((b) => b.id);
      const params = {
        ids: this.id,
        toShareUser: userIds.join(",")
      };
      const rsp = await btnService.mdDataShare(params);
      console.log(rsp);
      Message.success("操作成功");
      this.closeFn();
    },
    @request(false)

    // 保存
    save() {
      if (this.selectData.length === 0) {
        this.$message.warning("请选择共享人");
        return;
      }
      this.commit();
    },

    // 关闭弹窗
    closeFn() {
      this.$emit("update:dialogVisible", false);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
